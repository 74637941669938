/* global google */
import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import MapIcon from "../../../images/map.png"

const style = {
    width: '220px',
    height: '180px'
};

class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            icon: {
                url: MapIcon,
                scaledSize: new google.maps.Size(25, 25)
            }
        };
        this.handleMapClick = this.handleMapClick.bind(this);
    }

    handleMapClick = (ref, map, ev) => {
        const location = ev.latLng;
        this.setState(prevState => ({
            locations: [...prevState.locations, location]
        }));
        map.panTo(location);
    };

    render() {
        return (
            <div className="map-container">
                <Map
                    google={this.props.google}
                    style={style}
                    className={"map"}
                    zoom={this.props.zoom}
                    initialCenter={this.props.center}
                    onClick={this.handleMapClick}
                    fullscreenControl={false}
                >

                    <Marker
                        title={'The marker`s title will appear as a tooltip.'}
                        name={'SOMA'}
                        icon={this.state.icon}
                        position={{lat: this.props.center.lat, lng: this.props.center.lng}} />
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyC0rOUx-xxZ4scdUKiRf6XX3ik-LERerME",
    libraries: []
})(MapContainer);