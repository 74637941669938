import React,{Component} from 'react';
import {toast} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import TransactionList from '../../presentational/transaction-list';

require('dotenv').config();

toast.configure({
    autoClose: 2000,
    draggable: false,
});

class Transactions extends Component {

    render() {
        return (
            <div className="col-12" style={{padding:'15px 10px'}}>
                <h4 className="text-title">Transactions</h4>
                <TransactionList />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);