import moment from 'moment'

class DateFormat {
    static date(data) {
        return moment(data).format('MM-DD-Y')
    }

    static dateTime(data) {
        return moment(data).format('MM-DD-Y HH:mm:ss')
    }

    static amount(data) {
        return data.toFixed(2);
    }

    static urlQuery(params) {
        let url = '?'
        Object.keys(params).map(function (index) {
            url = url + "&" + index + "=" + params[index]
            return url
        })
        return url
    }
}

export default DateFormat;