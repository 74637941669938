import React, {Component} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";

import DateFormat from "../../../libraries/data-format-helper";


export default class UploadsHistoryTable extends Component {

    render() {
        return (
            <div className="row" style={{padding:'0px 40px'}}>
                <div className="col-12 card" style={{padding:'10px'}}>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>File</TableCell>
                                    <TableCell>Records</TableCell>
                                </TableRow>
                            </TableHead>
                            {(this.props.loading) ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <HistoryUploadsRows rows={this.props.rows}
                                                        handleSearchUpload={this.props.handleSearchUpload}/>
                                )
                            }
                        </Table>
                    </div>
                    <div>
                        <TablePagination
                            rowsPerPageOptions={[50, 75, 100]}
                            component="div"
                            count={this.props.totalData}
                            rowsPerPage={this.props.perPage}
                            page={this.props.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.props.handleChangePage}
                            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

class HistoryUploadsRows extends Component {

    render() {
        let handleSearch = this.props.handleSearchUpload
        if (this.props.rows === null) {
            return <TableBody/>
        }
        return (
            <TableBody>
                {this.props.rows.map(function (item, i) {
                    return (
                        <TableRow key={"upload" + i} onClick={() => handleSearch(item.id)}>
                            <TableCell>{DateFormat.dateTime(item.createdAt)}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.file}</TableCell>
                            <TableCell>{item.recordsComplete}/{item.records}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }
}