import React, {Component} from 'react';
import {toast, ToastContainer} from "react-toastify";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SalesOrderedLists from "./ordered-list";
import SalesDeliveredLists from "./delivered-list";
import SalesShippedLists from "./shipped-list";
import TransponderWaitingLists from "./waiting-list";

toast.configure({
    autoClose: 2000,
    draggable: false,
});


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


class Sales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            showOrder: true,
            showShipped: false,
            showDelivered: false,
            showWaiting: false
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(e, newValue) {
        let order = false;
        let shipped = false;
        let delivered = false;
        let waiting = false;
        if (newValue === 0) {
            order = true;
            shipped = false;
            delivered = false;
            waiting = false;
        }
        if (newValue === 1) {
            order = false;
            shipped = true;
            delivered = false;
            waiting = false;
        }
        if (newValue === 2) {
            order = false;
            shipped = false;
            delivered = true;
            waiting = false;
        }
        if (newValue === 3) {
            order = false;
            shipped = false;
            delivered = false;
            waiting = true;
        }
        this.setState({
            activeTab: newValue,
            showOrder: order,
            showShipped: shipped,
            showDelivered: delivered,
            showWaiting: waiting
        });
    }

    render() {
        return (
            <div className="row" style={{margin:'0px -35px'}}>
                <div className="col-12">
                    <AppBar position="static" color="default">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className='text-title'>Toll Tag Purchase</h2>
                            </div>
                        </div>

                        <Tabs
                            value={this.state.activeTab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Ordered" {...a11yProps(0)} />
                            <Tab label="Shipped" {...a11yProps(1)} />
                            <Tab label="Delivered" {...a11yProps(2)} />
                            <Tab label="Waiting Toll Tag" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={this.state.activeTab} index={0}>
                        {(this.state.showOrder === true) ? (<SalesOrderedLists/>) : ('')}
                    </TabPanel>
                    <TabPanel value={this.state.activeTab} index={1}>
                        {(this.state.showShipped === true) ? (<SalesShippedLists/>) : ('')}
                    </TabPanel>
                    <TabPanel value={this.state.activeTab} index={2}>
                        {(this.state.showDelivered === true) ? (<SalesDeliveredLists/>) : ('')}
                    </TabPanel>
                    <TabPanel value={this.state.activeTab} index={3}>
                        {(this.state.showWaiting === true) ? (<TransponderWaitingLists/>) : ('')}
                    </TabPanel>
                </div>
                <ToastContainer/>
            </div>
        )
    }
}

export default Sales;