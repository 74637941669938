import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import Api from "../../../libraries/api";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {Link} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import {toast} from "react-toastify";
import DateFormat from "../../../libraries/data-format-helper";

export default class SalesShippedLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            perPage: 50,
            currentPage: 0,
            currentPageTable: 0,
            totalData: 0,
            showDetail: false,
            showEdit: false,
            search: '',
            purchaseData: [],
            purchaseHistory: [],
            trackId: '',
            transponderShippedId: '',
            deliveryProviderId: ''
        };
        this.getPurchaseShipped();
        this.handlePurchaseShipped = this.handlePurchaseShipped.bind(this);
        this.handleShippedOrder = this.handleShippedOrder.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowEdit = this.handleShowEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    handleShippedOrder(deliveryProviderDefaultId) {
        let purchase = this.state.purchaseData;
        let providerId = (this.state.deliveryProviderId === '') ? deliveryProviderDefaultId : this.state.deliveryProviderId;
        let params = {
            userId: purchase["userId"],
            addressId: purchase["addressId"],
            transponderPriceId: purchase["transponderPriceId"],
            transponderId: purchase["transponderId"],
            notifyStatus: purchase["notifyStatus"],
            deliveryProviderId: providerId,
            transponderShippedId: this.state.transponderShippedId,
            trackId: this.state.trackId,
            status: 'sent',
        }
        Api.put('/purchase/' + purchase["id"], params, false).then(resp => {
            toast.success('Purchase successfully updated', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            this.setState({
                showDetail: false,
                showEdit: false,
                trackId: '',
                transponderShippedId: '',
                deliveryProviderId: deliveryProviderDefaultId
            }, () => {
                this.getPurchaseShipped();
            });
        }).catch(err => {
            console.log(err)
            toast.error('Invalid format data', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    getPurchaseShipped() {
        let currentPage = this.state.currentPage + 1;
        let url = "/purchase/type/sent?perPage=" + this.state.perPage + "&pageNumber=" + currentPage + "&search=" + this.state.search;
        Api.get(url, [], false).then((resp) => {
            this.setState({
                rows: resp["data"]["Purchases"],
                loading: false,
                trackId: '',
                totalData: resp["data"]["meta"]["totalData"]
            });
        });
    }

    handleClose() {
        this.setState({
            showDetail: false,
            showEdit: false,
        })
    }

    handleShowEdit() {
        let showEdit = (this.state.showEdit !== true);
        this.setState({showEdit: showEdit});
    }

    handlePurchaseShipped(purchaseId) {
        let url = "/purchase/detail/" + purchaseId;
        Api.get(url, [], false).then((resp) => {
            this.setState({
                showDetail: true,
                purchaseData: resp["data"]["Purchase"],
                purchaseHistory: resp["data"]["purchaseHistory"]
            })
        });

    }

    handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                rows: [],
                currentPage: 0,
                currentPageTable: 0,
                loading: true,
            }, () => {
                this.getPurchaseShipped();
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.getPurchaseShipped();
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: event.target.value,
            currentPage: 0,
            currentPageTable: 0,
            loading: true,
        }, () => {
            this.getPurchaseShipped()
        });
    };

    render() {
        return (
            <div className="row" style={{padding: '0px 20px'}}>
                <div className="col-12 card">
                    <div className="row align-items-center">
                        <div className="col-md-4 search-bar">
                            <TextField
                                type="text"
                                id={"adornment-search-shipped"}
                                name="search"
                                placeholder="ordered"
                                onChange={(e) => this.handleChange(e, 'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Search button"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                        <div className="col-md-2 filter">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    margin="normal"
                                    id={"start_date_shipped"}
                                    label="Date"
                                    format={"MM/DD/YYYY"}
                                    value={this.state.start_date}
                                    onChange={(date) => this.handleChangeSearch(date, 'start_date')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <PurchaseShippedDetail showDetail={this.state.showDetail}
                                           handleClose={this.handleClose}
                                           handleChange={this.handleChange}
                                           handleShippedOrder={this.handleShippedOrder}
                                           purchaseData={this.state.purchaseData}
                                           purchaseHistory={this.state.purchaseHistory}
                                           showEdit={this.state.showEdit}
                                           handleShowEdit={this.handleShowEdit}
                                           trackId={this.state.trackId}
                                           transponderShippedId={this.state.transponderShippedId}
                                           deliveryProviderId={this.state.deliveryProviderId}/>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order</TableCell>
                                    <TableCell>Request Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Shipped Date</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Zip Code</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.loading ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <PurchaseShippedTable rows={this.state.rows}
                                                          handlePurchaseShipped={this.handlePurchaseShipped}/>
                                )
                            }

                        </Table>
                    </div>
                    <div>
                        <TablePagination
                            rowsPerPageOptions={[50, 75, 100]}
                            component="div"
                            count={this.state.totalData}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

class PurchaseShippedTable extends Component {

    render() {
        let handlePurchaseShipped = this.props.handlePurchaseShipped;
        return (
            <TableBody>
                {this.props.rows.map(function (item, i) {
                    return (
                        <TableRow key={i} onClick={() => handlePurchaseShipped(item.id)}>
                            <TableCell style={{color:'#008299'}}>{item.id}</TableCell>
                            <TableCell>{DateFormat.dateTime(item.created)}</TableCell>
                            <TableCell>shipped</TableCell>
                            <TableCell>{DateFormat.dateTime(item.transponderShippedDate)}</TableCell>
                            <TableCell>{item.userName}</TableCell>
                            <TableCell>{item.postalCode}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }
}

class PurchaseShippedDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryProvider: [],
            deliveryProviderDefaultId: '',
        }
    }

    componentDidMount() {
        /*Delivery Catalog*/
        Api.get('/delivery/provider', true).then(resp => {
            if (resp.data) {
                this.setState({
                    deliveryProvider: resp.data.deliveryProvider,
                    deliveryProviderDefaultId: resp.data.deliveryProviderDefaultId
                });
            }
        })
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    render() {
        let handleClose = this.props.handleClose;
        let handleShippedOrder = this.props.handleShippedOrder;
        let handleShowEdit = this.props.handleShowEdit;
        let purchase = this.props.purchaseData;
        let purchaseHistory = this.props.purchaseHistory;
        let handleChange = this.props.handleChange;
        let providerId = (this.props.deliveryProviderId === '') ? this.state.deliveryProviderDefaultId : this.props.deliveryProviderId;
        return (
            <Dialog
                maxWidth={'md'}
                scroll={"body"}
                open={this.props.showDetail}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className="dialog-custom">
                <DialogTitle id="form-dialog-title">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <span>Order</span>
                        </div>
                        <div className="col-md-6">
                            <p className="mb-0"><label className="title d-inline">Status</label>
                                <i className="status text-capitalize"> Shipped</i>
                            </p>
                        </div>
                    </div>
                    <button onClick={handleClose} className="btn-close">
                        <i className="fas fa-times"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="title">Account number</label>
                                <p className="pl-2 pl-lg-3">{purchase["userId"]}</p>
                                <Link to={'/accounts/detail/' + purchase["userId"]} className="btn btn-blue-trans mb-3">Go
                                    to Account</Link>
                                <label className="title">Contact and Shipping</label>
                                <p className="pl-2 pl-lg-3">
                                    {purchase["fullName"]}<br/>
                                    {purchase["address"]}<br/>
                                    {purchase["city"]}, {purchase["stateId"]} {purchase["postalCode"]}<br/>
                                    {purchase["email"]}<br/>
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="title">Request Date</label>
                                        <p className="pl-2 pl-lg-3">{DateFormat.dateTime(purchase["created"])}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Shipped Date</label>
                                        <p className="pl-2 pl-lg-3">{DateFormat.dateTime(purchase["transponderShippedDate"])}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Delivery Provider</label>
                                        <p className="pl-2 pl-lg-3">{purchase["provider"]}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Tracking Id</label>
                                        <p className="pl-2 pl-lg-3">{purchase["trackId"]}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Toll Tag ID</label>
                                        <p className="pl-2 pl-lg-3">{purchase["transponderShippedId"]}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="title">Toll Tag Fee</label>
                                        <p className="pl-2 pl-lg-3">$0.0</p>
                                    </div>
                                </div>
                                {(this.props.showEdit === true) ?
                                    (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextField
                                                    select
                                                    id="deliveryProviderId"
                                                    name="deliveryProviderId"
                                                    label="Delivery Provider"
                                                    onChange={(e) => handleChange(e, 'deliveryProviderId')}
                                                    minLength={4}
                                                    maxLength={50}
                                                    value={providerId}
                                                    fullWidth
                                                >
                                                    {this.state.deliveryProvider.map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.provider}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type='text'
                                                    id='trackId'
                                                    name="trackId"
                                                    label="Tracking Number"
                                                    onChange={(e) => handleChange(e, 'trackId')}
                                                    minLength={4}
                                                    maxLength={50}
                                                    value={this.state.trackId}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type='text'
                                                    id='transponderShippedId'
                                                    name="transponderShippedId"
                                                    label="Toll Tag Id"
                                                    onChange={(e) => handleChange(e, 'transponderShippedId')}
                                                    minLength={4}
                                                    maxLength={50}
                                                    value={this.state.transponderShippedId}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-12">
                                                <label className="title"></label>
                                                <p className="pl-2 pl-lg-3"></p>
                                            </div>
                                            <div className="col text-right">
                                                <button
                                                    className="btn btn-blue-trans"
                                                    onClick={() => handleShowEdit()}
                                                    disabled={this.props.loadingButton && 'disabled'}>
                                                    Cancel {this.props.loadingButton &&
                                                <i className="fa fa-spinner fa-spin"> </i>}</button>
                                            </div>
                                            <div className="col text-right">
                                                <button
                                                    className="btn btn-blue-trans"
                                                    onClick={() => handleShippedOrder(this.state.deliveryProviderDefaultId)}
                                                    disabled={this.props.loadingButton && 'disabled'}>
                                                    Save & Ship{this.props.loadingButton &&
                                                <i className="fa fa-spinner fa-spin"> </i>}</button>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="col text-right">
                                            <button
                                                className="btn btn-blue-trans"
                                                onClick={() => handleShowEdit()}
                                                disabled={this.props.loadingButton && 'disabled'}>
                                                Edit{this.props.loadingButton &&
                                            <i className="fa fa-spinner fa-spin"> </i>}</button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            <label>History</label>
                            <div className="table-wrapper">
                                <Table className="table-list mt-3" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Toll Tag Id</TableCell>
                                            <TableCell>Delivery Provider</TableCell>
                                            <TableCell>Tracking Id</TableCell>
                                            <TableCell>Shipping Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {purchaseHistory.map(function (item, i) {
                                            return (
                                                <TableRow key={"history-shipped" + item.id}>
                                                    <TableCell>{item.transponderId}</TableCell>
                                                    <TableCell>{item.provider}</TableCell>
                                                    <TableCell>{item.trackId}</TableCell>
                                                    <TableCell>{DateFormat.dateTime(item.transponderShippedDate)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}