import React, {Component} from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import {Link} from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Icon from "../../../images/prior.png";
import Map from '../../presentational/map';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

toast.configure({
    autoClose: 2000,
    draggable: false,
});

class TicketLists extends Component {
    constructor(props) {

        super(props);
        this.state = {
            firstTime: true,
            loading: true,
            loadingButton: false,
            loadingZipcode: false,
            errors: {},
            search: '',
            start_date: null,
            end_date: null,
            list_assignee: [],
            list_status: [
                'New',
                'Open',
                'Pending',
                'On-hold',
                'Solved',
                'Closed',
            ],
            s_assignee: '',
            s_status: '',

            showDetail: false,

            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            total: 0,
            perPage: 50,
            currentPage: 1,
            currentPageTable: 0,
            totalData: 0,

            idUser: this.props.user && this.props.user,
            idTicket: '',
            ticketNumber: '',
            accountId: '',
            fullName: '',
            email: '',
            password: '',
            phone: '',
            avatar: '',
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            stateName: '',
            stateId: '',
            status: '',

            user: {},
            room: {},
            messages: {},
            typing: {},

            userId: ''

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleShowDetail = this.handleShowDetail.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {


        this.__fetchData(false);

        // if(this.state.idUser !==  undefined) {
        //     this.__fetchDataUser(false);
        // }else {
        //     this.__fetchData(false);
        // }

        Api.get('/tickets/assignees', false).then(resp => {

            if (resp.data) {

                this.setState({
                    list_assignee: resp.data.assignees
                });

            }

        }).catch(err => {
            console.log(err);
        });

    }

    __fetchData = update => {
        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let keyword = this.state.search === '' ? '' : '&keyword=' + this.state.search;

        let start_date = this.state.start_date === null ? '' : '&start=' + moment(this.state.start_date).format('YYYY-MM-DD');
        let end_date = this.state.end_date === null ? '' : '&end=' + moment(this.state.end_date).format('YYYY-MM-DD');
        let s_assignee = this.state.s_assignee === '' ? '' : '&assignee=' + this.state.s_assignee;
        let s_status = this.state.s_status === '' ? '' : '&status=' + this.state.s_status;

        let searchById = this.state.idUser ? '/' + this.state.idUser : '';

        let route = '/tickets' + searchById + '?perPage=' + this.state.perPage + '&pageNumber=' + page + keyword + start_date + end_date + s_assignee + s_status;

        let idTicket = this.state.idTicket;

        Api.get(route, false).then(resp => {

            if (resp.data) {

                let data = '';

                resp.data.tickets.forEach(function (o) {

                    if (o.id === idTicket) {
                        o.selected = true;
                        data = o;
                    } else {
                        o.selected = false;
                    }

                });

                if (typeof data === 'object') {

                    this.handleShowDetail(data);

                }

                this.setState({
                    loading: false,
                    rows: resp.data.tickets,
                    total: resp.data.meta.total,
                    perPage: parseInt(resp.data.meta.perPage),
                    currentPage: resp.data.meta.currentPage,
                    currentPageTable: resp.data.meta.currentPage - 1,
                    totalData: resp.data.meta.totalData,
                });

            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleChange(e, prop) {

        this.setState({

            [prop]: e.target.value
        })

    };

    handleChangeSearch(e, prop) {

        let val;

        if (prop === 'start_date' || prop === 'end_date') {
            val = e;
        } else {
            val = e.target.value
        }

        this.setState({

            [prop]: val
        }, () => this.handleClickSearch());

    };

    handleKeyPressSearch = (event) => {

        if (event.key === 'Enter') {

            this.handleClickSearch();

        }

    };

    handleClickSearch() {

        this.setState({
            loading: true,
        });

        this.__fetchData(false);

    };

    handleShowDetail = (data) => {

        let arrObjRows = this.state.rows;

        arrObjRows.forEach(function (o) {
            o.selected = o.id === data.id;
        });

        this.setState({
                showDetail: true,
                idTicket: data.id,
                ticketNumber: data.ticketNumber,
                transponderNumber: data.transponderNumber,
                created: data.created,
                priority: data.priority,
                assignee: data.assignee,
                category: data.category,
                content: data.content,
                status: data.status,
                email: data.email,
                fullName: data.fullName,
                phone: data.phone,
                latitude: data.latitude,
                longitude: data.longitude,
                diagnostics: data.diagnostics,
                zendeskProfileUrl: data.zendeskProfileUrl,
                userId: data.userId
            }
        );
    };


    handleClose = e => {
        this.setState(prevState => ({
                showDetail: false,
            })
        )
    };

    handleShowEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: true,
        })
    };

    handleCloseEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: false,
        })
    };

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    };

    handleUpdate = e => {
        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );


        this.showMessage(true, 'Success');

    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: parseInt(event.target.value, 50),
            currentPage: 1,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    render() {
        return (
            <div className="row" style={{padding:'0px 10px'}}>
                <div className="col-12 card" style={{padding:'20px'}}>
                    <div className="row align-items-center">
                        <div className="col-md-4 search-bar">
                            <TextField
                                type="text"
                                id="adornment-search-zendesk"
                                name="search"
                                placeholder="Search by ticket number"
                                onChange={(e) => this.handleChange(e, 'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Search button"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                        <div className="col-md-2 filter">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    margin="normal"
                                    id="start_date"
                                    label="Start date"
                                    format={"MM/DD/YYYY"}
                                    value={this.state.start_date}
                                    onChange={(date) => this.handleChangeSearch(date, 'start_date')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 filter">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    margin="normal"
                                    id="end_date"
                                    label="End date"
                                    format={"MM/DD/YYYY"}
                                    value={this.state.end_date}
                                    onChange={(date) => this.handleChangeSearch(date, 'end_date')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-2 filter">
                            <TextField
                                select
                                id="s_assignee"
                                name="s_assignee"
                                label="Assignee"
                                onChange={(e) => this.handleChangeSearch(e, 's_assignee')}
                                value={this.state.s_assignee}
                                fullWidth
                            >
                                <MenuItem value="">
                                    Assignee
                                </MenuItem>
                                {this.state.list_assignee.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="col-md-2 filter">
                            <TextField
                                select
                                id="s_status"
                                name="s_status"
                                label="Status"
                                onChange={(e) => this.handleChangeSearch(e, 's_status')}
                                value={this.state.s_status}
                                fullWidth
                            >
                                <MenuItem value="">
                                    Status
                                </MenuItem>
                                {this.state.list_status.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ticket</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Account number</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell><img src={Icon} alt="icon"/></TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Assignee</TableCell>
                                    <TableCell>Content</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center" className="py-5">
                                            <CircularProgress color="primary"/>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.length === 0 ? (
                                        <TableRow style={{height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={8} style={{textAlign: "center"}}>No tickets</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.map((row, index) => {

                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    onClick={() => {
                                                        this.handleShowDetail(row);
                                                    }}
                                                    selected={row.selected}
                                                >
                                                    <TableCell style={{color:'#008299'}}>{row.ticketNumber === null ? 'NA' : row.ticketNumber}</TableCell>
                                                    <TableCell>{row.status === null ? 'NA' : row.status}</TableCell>
                                                    <TableCell>{row.transponderNumber === null ? 'NA' :
                                                        <NumberFormat className="title mb-0 numb"
                                                                      value={row.transponderNumber} displayType={'text'}
                                                                      format="#### ##### ##### #"/>
                                                    }</TableCell>
                                                    <TableCell>{row.created === null ? 'NA' : row.created}</TableCell>
                                                    <TableCell>{row.priority === null ? 'NA' : row.priority}</TableCell>
                                                    <TableCell>{row.category === null ? 'NA' : row.category}</TableCell>
                                                    <TableCell>{row.assignee === null ? 'NA' : row.assignee}</TableCell>
                                                    <TableCell>{row.content === null ? 'NA' : row.content}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[50, 75, 100]}
                        component="div"
                        count={this.state.totalData}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
                <Dialog
                    maxWidth={'md'}
                    open={this.state.showDetail}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className="dialog-custom">
                    <DialogTitle id="form-dialog-title">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <span>Ticket {this.state.ticketNumber}</span>
                            </div>
                            <div className="col-md-6">
                                <p className="mb-0"><label className="title d-inline">Status</label> <i
                                    className="status text-capitalize">{this.state.status}</i></p>
                            </div>
                        </div>
                        <button onClick={this.handleClose} className="btn-close">
                            <i className="fas fa-times"> </i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="title">Account number</label>
                                    <p className="pl-2 pl-lg-3">{
                                        this.state.userId
                                        /*this.state.transponderNumber &&
                                        <NumberFormat className="title mb-0 numb" value={this.state.transponderNumber} displayType={'text'} format="#### ##### ##### #" />*/
                                    }</p>
                                    <Link to={'/accounts/detail/' + this.state.userId}
                                          className="btn btn-blue-trans mb-3">Go to Account</Link>

                                    <label className="title">Contact</label>
                                    <p className="pl-2 pl-lg-3">{this.state.fullName}<br/>
                                        {this.state.email}<br/>
                                        {this.state.phone}
                                    </p>

                                    <label className="title mb-0">Location</label>
                                    <Map center={{lat: this.state.latitude, lng: this.state.longitude}} zoom={4}/>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="title">Date created</label>
                                            <p className="pl-2 pl-lg-3">{this.state.created}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="title">Priority</label>
                                            <p className="pl-2 pl-lg-3">{this.state.priority}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="title">Category</label>
                                            <p className="pl-2 pl-lg-3">{this.state.category}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="title">Assignee</label>
                                            <p className="pl-2 pl-lg-3">{this.state.assignee}</p>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label className="title">Diagnostics</label>
                                            {
                                                this.state.diagnostics &&
                                                this.state.diagnostics.split(' ').map((item, idx) => {
                                                    return (
                                                        <p key={idx} className="mb-0 pl-2 pl-lg-3">{item}</p>
                                                    )
                                                })}
                                        </div>

                                        <div className="col-12">
                                            <label className="title">Content</label>
                                            <p className="pl-2 pl-lg-3">{this.state.content}</p>
                                        </div>
                                        <div className="col-12 text-right">
                                            <a className="btn btn-blue-trans" href={this.state.zendeskProfileUrl}
                                               target="_blank" rel="noopener noreferrer">Open in Zendesk</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
                <ToastContainer/>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(TicketLists);