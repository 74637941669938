import React, {Component} from "react";
import moment from "moment";
import {CircularProgress} from "@material-ui/core";
import Api from "../../../libraries/api";

class DashboardTotal extends Component {

    constructor(props) {
        super(props);
        let today = new Date()
        this.state = {
            loading: true,
            date: moment(today).format("MMMM DD, Y"),
            dashboardData: []
        };
        this.getDashboardInfo = this.getDashboardInfo.bind(this);
        this.getDashboardInfo()
    }

    getDashboardInfo() {
        Api.get("/reports/total-dashboard", true).then(resp => {
                if (resp.data) {
                    let response = resp.data;
                    this.setState({loading: false, dashboardData: response})
                }
            }
        ).catch(err => {
                console.log(err);
            }
        );
    }

    render() {
        return <div>
            <div className="row card" style={{padding:'20px'}}>
                <div className="col-md-12 col-lg-12">
                    <div className="row title-dashboard">
                        <div className="col-6">
                            <div>Insight | {this.state.date}</div>
                            <br/>
                        </div>
                    </div>
                    <TotalInfo loading={this.state.loading}
                               dashboardData={this.state.dashboardData}/>
                </div>
            </div>
        </div>
    }
}

class TotalInfo extends Component {

    render() {
        let dashboardData = this.props.dashboardData
        if (this.props.loading === true) {
            return(
                <div className="col-12 content-center-center">
                    <CircularProgress />
                </div>)
        } else {
            return (<div className="row col-12">
                {Object.keys(dashboardData).map(function (item, i) {
                    let id = "dsh" + i
                    let total = dashboardData[item]
                    return (
                        <div key={id} className={"col-4"}>
                            <div style={{textAlign:'center'}}>
                                <h3>{total}</h3>
                                <h2 className="text-black">{item}</h2>
                                
                            </div>
                        </div>
                    )
                })}
            </div>)
        }
    }
}

export default DashboardTotal