import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter, Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";

class Sidebar extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    handleLogout = () => {
        AuthHelper.logOut();
    };

    render() {
        const {location} = this.props;
        const perms = this.props.permission_state;
        return (
            <aside
                className={
                    "side-nav " + (this.props.toggle_sidebar_state ? "show" : "")
                }
            >
                <ul className="nav flex-column">
                    <li className="nav-item name d-md-none">
                        Welcome, {this.props.profile_state.fullName}
                    </li>
                    <li className={"nav-item " + (location.pathname === "/" && "active")}>
                        <div>
                            <Link to="/">
                                <i class="fas fa-home" style={{width:'20px',marginRight:'10px'}}></i>
                                Home
                            </Link>
                        </div>
                    </li>
                    {perms.includes("accounts") && (
                        <li
                            className={
                                location.pathname === "/accounts"
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link to="/accounts">
                                <i class="fas fa-users" style={{width:'20px',marginRight:'10px'}}></i>
                                Accounts
                            </Link>
                        </li>
                    )}

                    {perms.includes("fulfillment") && (
                        <li
                            className={
                                location.pathname === "/fulfillment"
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link to="/fulfillment">
                                <i class="fas fa-bookmark" style={{width:'20px',marginRight:'10px'}}></i>
                                Fulfillment
                            </Link>
                        </li>
                    )}

                    {perms.includes("sales") && (
                        <li
                            className={
                                location.pathname === "/sales" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/sales">
                                <i class="fas fa-file-signature" style={{width:'20px',marginRight:'10px'}}></i>
                                Orders
                            </Link>
                        </li>
                    )}

                    {perms.includes("tickets") && (
                        <li
                            className={
                                location.pathname === "/tickets" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/tickets">
                                <i class="fas fa-ticket-alt" style={{width:'20px',marginRight:'10px'}}></i>
                                Tickets
                            </Link>
                        </li>
                    )}

                    {perms.includes("transactions") && (
                        <li
                            className={
                                location.pathname === "/transactions"
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link to="/transactions">
                                <i class="fas fa-exchange-alt" style={{width:'20px',marginRight:'10px'}}></i>
                                Transactions
                            </Link>
                        </li>
                    )}

                    {perms.includes("modules") && (
                        <li
                            className={
                                location.pathname === "/modules" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/modules">
                                <i class="fas fa-boxes" style={{width:'20px',marginRight:'10px'}}></i>
                                Modules
                            </Link>
                        </li>
                    )}

                    {perms.includes("payrules") && (
                        <li
                            className={
                                location.pathname === "/payment-rules" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/payment-rules">
                                <i class="fas fa-file-invoice-dollar" style={{width:'20px',marginRight:'10px'}}></i>
                                Pay rules
                                </Link>
                        </li>
                    )}

                    {perms.includes("reports") && (
                        <li
                            className={
                                location.pathname === "/reports" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/reports">
                                <i class="far fa-clipboard" style={{width:'20px',marginRight:'10px'}}/>
                                Reports
                            </Link>
                        </li>
                    )}

                    {perms.includes("users") && (
                        <li
                            className={
                                location.pathname === "/access-management"
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link to="/access-management">
                                <i class="fas fa-key" style={{width:'20px',marginRight:'10px'}}/>
                                Access Mgmt
                            </Link>
                        </li>
                    )}
                    <li
                        className={
                            location.pathname === "/" ? "nav-item active" : "nav-item"
                        }
                    >
                        <button className="nav-item active" onClick={this.handleLogout} style={{marginLeft:'8px'}}>
                            <i class="fas fa-sign-out-alt"style={{width:'20px',marginRight:'10px'}} />
                            Sign out
                        </button>
                    </li>
                </ul>
            </aside>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state,

        permission_state: state.permission_state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(RootActions, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Sidebar));
