import React, {Component} from "react";
import Api from "../../../libraries/api";
import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {TextField} from "@material-ui/core";

export default class ResendOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUser: false
        }
    }

    render() {
        if (this.props.newUser === true) {
            return <SendOtpNewUser/>
        } else {
            let contact = this.props.userOtpContact
            return <SendOtpExistUser userOtpContact={contact}/>
        }
    }
}

class SendOtpExistUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpResend: false,
            otpCode: '',
            loading: false
        }
        this.showNewOtp = this.showNewOtp.bind(this)
    }

    handleClose = e => {
        this.setState(prevState => ({
                otpResend: false,
            })
        )
    };

    showNewOtp() {
        const otpAccountUrl = "/oneTimePassword";
        this.setState({
            loading: true
        })
        let parameters = {
            type: "admin",
            credentials: this.props.userOtpContact
        };
        Api.post(otpAccountUrl, parameters, false).then((resp) => {
            this.setState({
                otpResend: true,
                otpCode: resp.data["otp"],
                loading: false,
            })
        }).catch(err => {
                let i;
                let errorMsg = "";
                console.log(err)
                const erroList = Object.keys(err.errors);
                for (i = 0; i < erroList.length; i++) {
                    const index = erroList[i].toString();
                    errorMsg = errorMsg + err.errors[index]
                }
                toast.error(errorMsg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
        );
    }

    render() {
        if (this.state.loading === true) {
            return <CircularProgress/>
        }
        if (this.state.otpResend === false) {
            return <button className="btn-trans col-3" onClick={this.showNewOtp}>Resend OTP</button>
        } else {
            return <div>
                <Dialog
                    maxWidth={'md'}
                    open={this.state.otpResend}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className="dialog-custom">
                    <DialogTitle id="form-dialog-title">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <span>OTP User</span>
                            </div>
                        </div>
                        <button onClick={this.handleClose} className="btn-close">
                            <i className="fas fa-times"> </i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        OTP: {this.state.otpCode}
                    </DialogContent>
                </Dialog>
            </div>
        }
    }
}

class SendOtpNewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userContact: undefined,
            showOtpRegister: false,
            otpCode: undefined,
            loading: false
        }
        this.registerOtp = this.registerOtp.bind(this)
        this.generateNewOtp = this.generateNewOtp.bind(this)
    }

    registerOtp() {
        this.setState({
            otpCode: undefined,
            showOtpRegister: true,
        })
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    generateNewOtp() {
        this.setState({loading: true})
        const otpAccountUrl = "/oneTimePassword";
        let parameters = {
            type: "admin",
            credentials: this.state.userContact
        };
        Api.post(otpAccountUrl, parameters, false).then((resp) => {
            console.log(parameters)
            this.setState({
                loading: false,
                otpResend: true,
                otpCode: resp.data["otp"],
            })
        }).catch(err => {
                let i;
                let errorMsg = "";
                console.log(err)
                const erroList = Object.keys(err.errors);
                for (i = 0; i < erroList.length; i++) {
                    const index = erroList[i].toString();
                    errorMsg = errorMsg + err.errors[index]
                }
                toast.error(errorMsg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
        );
    }

    handleClose = e => {
        this.setState(prevState => ({
                showOtpRegister: false,
            })
        )
    };

    render() {
        if (this.state.showOtpRegister === false) {
            return (
                <button className="btn-trans " onClick={this.registerOtp}>OTP New User</button>
            );
        } else {
            let optCode = this.state.otpCode;
            return <div>
                <Dialog
                    maxWidth={'md'}
                    open={this.state.showOtpRegister}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className="dialog-custom">
                    <DialogTitle id="form-dialog-title">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <span>OTP User</span>
                            </div>
                        </div>
                        <button onClick={this.handleClose} className="btn-close">
                            <i className="fas fa-times"> </i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        {(this.state.loading === true) ? (
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <CircularProgress/>
                                </div>
                            </div>) : ('')}
                        <div className="row">
                            {(optCode === undefined) ?
                                (
                                    <div className="row">
                                        <div className="col-md-11 col-lg-11">
                                            <TextField id="userOtp"
                                                       label="Email or Phone"
                                                       variant="outlined"
                                                       name="search"
                                                       onChange={(e) => this.handleChange(e, 'userContact')}
                                                       style={{margin: 8}}
                                                       fullWidth
                                                       margin="normal"
                                                       InputLabelProps={{
                                                           shrink: true,
                                                       }}
                                            />
                                        </div>
                                        <div className="col-md-1 col-lg-1">
                                            <button onClick={this.generateNewOtp} className="btn btn-blue">
                                                Generate OTP
                                            </button>
                                        </div>
                                    </div>
                                )
                                :
                                (<div className="col-md-9 col-lg-9">OTP code: {this.state.otpCode}</div>)
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        }
    }
}