import React, {Component} from "react";
import Api from "../../../libraries/api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {toast} from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";

export default class FeeRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            product: '',
            fee: '',
            feeAvg: '',
            loading: true,
            rows: [],
            showForm: false,
        };
        this.getFeeRules();
        this.handleFeeDetail = this.handleFeeDetail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateFee = this.handleUpdateFee.bind(this);
    }

    handleFeeDetail(id) {
        let feeDetail = "/payment-rules/id/" + id;
        Api.get(feeDetail, [], false).then((resp) => {
            this.setState({
                showForm: true,
                id: resp["data"]["paymentRules"]["id"],
                product: resp["data"]["paymentRules"]["product"],
                fee: resp["data"]["paymentRules"]["fee"],
                feeAvg: resp["data"]["paymentRules"]["feeAvg"],
                status: resp["data"]["paymentRules"]["status"],
            })
        });
    }

    handleUpdateFee() {
        const updatePayRuleUrl = "/payment-rules/id/" + this.state.id;
        let parameters = {
            product: this.state.product,
            fee: this.state.fee,
            feeAvg: this.state.feeAvg,
            transactionLimit: 0,
            status: this.state.status
        };
        Api.put(updatePayRuleUrl, parameters, false).then((resp) => {
            this.getFeeRules()
            toast.success(resp.messages, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }).catch(err => {
                toast.error(err.errors.messages, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        );
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    getFeeRules() {
        let paymentRulesConfigUrl = "/payment-rules?ruleType=fee";
        Api.get(paymentRulesConfigUrl, [], false).then((resp) => {
            this.setState({
                rows: resp["data"]["paymentRules"],
                loading: false,
                showForm: false,
            });
        });
    }

    render() {
        return (
            <div className="row" style={{padding:'0px 20px'}}>
                <div className="col-12 card">
                    <FeeRulesDetail showForm={this.state.showForm}
                                    handleChange={this.handleChange}
                                    handleUpdateFee={this.handleUpdateFee}
                                    id={this.state.id}
                                    product={this.state.product}
                                    fee={this.state.fee}
                                    feeAvg={this.state.feeAvg}
                                    status={this.state.status}/>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Alias</TableCell>
                                    <TableCell>Fee ($)</TableCell>
                                    <TableCell>Fee Avg (%)</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.loading ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <FeeRulesTable rows={this.state.rows} handleFeeDetail={this.handleFeeDetail}/>
                                )
                            }
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

class FeeRulesTable extends Component {

    render() {
        let handleFeeDetail = this.props.handleFeeDetail;
        return (
            <TableBody>
                {this.props.rows.map(function (item, i) {
                    return (
                        <TableRow key={i} onClick={() => handleFeeDetail(item.id)}>
                            <TableCell style={{color:'#008299'}}>{item.product}</TableCell>
                            <TableCell>{item.alias}</TableCell>
                            <TableCell>{item.fee}</TableCell>
                            <TableCell>{item.feeAvg}</TableCell>
                            <TableCell>
                                {item.status === 1 ? 'Enable' : 'Disable'}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }
}

class FeeRulesDetail extends Component {

    render() {
        let handleChange = this.props.handleChange;
        let handleUpdateFee = this.props.handleUpdateFee;
        if (this.props.showForm === true) {
            return (
                <div className="card" style={{padding:'20px 30px 0px'}}>
                    <div>
                        <div className="form-group row">
                            <div className="col-sm-3 pl-sm-0">
                                <TextField type='text' name="product" id="product" label="Product"
                                           value={this.props.product}
                                           onChange={(e) => handleChange(e, 'product')}/>
                            </div>
                            <div className="col-sm-3 pl-sm-0">
                                <TextField type='text' name="fee" id="fee"
                                           label="Fee"
                                           value={this.props.fee}
                                           onChange={(e) => handleChange(e, 'fee')}/>
                            </div>
                            <div className="col-sm-3 pl-sm-0">
                                <TextField type='text' name="feeAvg" id="feeAvg"
                                           label="Fee Avg(%$)"
                                           value={this.props.feeAvg}
                                           onChange={(e) => handleChange(e, 'feeAvg')}/>
                            </div>
                            <div className="col-sm-3 pl-sm-0">
                                <TextField
                                    select
                                    name="status" id="status"
                                    label="Status"
                                    value={this.props.status}
                                    onChange={(e) => handleChange(e, 'status')}>
                                    <MenuItem key={1} value={1}>
                                        Enable
                                    </MenuItem>
                                    <MenuItem key={0} value={0}>
                                        Disable
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group row">
                            <button
                                className="btn btn-blue-trans"
                                onClick={() => handleUpdateFee()}>Save
                            </button>
                        </div>
                    </div>
                    <br/>
                </div>
            )
        }
        return false
    }
}