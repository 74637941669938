import React, {Component} from 'react';
import AuthHelper from "../../../libraries/auth-helper";
import ChartTotalUsers from "./chart-total-users";
import ChartTickets from "./chart-tickets";
import ChartFulfillment from "./chart-fulfillment";
import ChartDevice from "./chart-device";
import ChartOld from "./chart-old";
import DashboardTotal from "./dashboard-total";

class ContentDashboard extends Component {

    constructor(props) {
        super(props);
        let profile = AuthHelper.getProfile()
        this.state = {
            role: profile.role["name"]
        }
    }

    render() {
        if (this.state.role === 'Super Admin' || this.state.role === 'Management') {
            return (
                <div>
                    <div className="row" style={{padding:'20px'}}>
                        <div className="col-md-12 col-lg-12">
                            <DashboardTotal/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <ChartTotalUsers/>
                                </div>
                                <div className='col-md-6'>
                                    <ChartDevice/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <ChartTickets/>
                                </div>
                                <div className='col-md-6'>
                                    <ChartFulfillment/>
                                </div>
                            </div>
                                <ChartOld/>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.role === 'Sales') {
            return (
                <div className="row content">
                    <div className="row col-12">
                        <div className="col-md-12 col-lg-12">
                            <ChartFulfillment/>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.role === 'Customer Support Rep') {
            return (
                <div className="row content">
                    <div className="col-12">
                        <div className="col-md-12 col-lg-12" >
                            <ChartTickets/>
                        </div>
                    </div>
                </div>
            )
        }
        return false
    }
}

export default ContentDashboard;