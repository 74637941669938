import React, {Component} from 'react';
import {TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Api from "../../../libraries/api";
import {history} from "../../../shared/configure-store";
import DateFormat from "../../../libraries/data-format-helper";
import ResendOtp from "./resend-otp";

class Accounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            perPage: 50,
            currentPage: 0,
            currentPageTable: 0,
            totalData: 0,
            search: '',
        }
        this.getAccountUser();
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    getAccountUser() {
        let currentPage = this.state.currentPage + 1;
        let url = "/accounts?perPage=" + this.state.perPage + "&pageNumber=" + currentPage + "&search=" + this.state.search;
        Api.get(url, [], false).then((resp) => {
            this.setState({
                rows: resp["data"]["users"],
                loading: false,
                totalData: resp["data"]["meta"]["totalData"]
            });
        });
    }

    handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                rows: [],
                currentPage: 0,
                currentPageTable: 0,
                loading: true,
            }, () => {
                this.getAccountUser();
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.getAccountUser();
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: event.target.value,
            currentPage: 0,
            currentPageTable: 0,
            loading: true,
        }, () => {
            this.getAccountUser();
        });
    };

    render() {
        return (
            <div className="row" style={{padding:'10px 20px'}}>
                <div className="col-md-6">
                    <h2 className='text-title'>Accounts</h2>
                </div>
                <div className="card col-md-12 col-lg-12">
                    <div className="row" style={{padding:'20px 20px 10px 0px'}}>
                        <div className="col search-bar">
                            <TextField
                                type="text"
                                id="adornment-search"
                                name="search"
                                placeholder="Search by name or account number"
                                onChange={(e) => this.handleChange(e, 'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                        <ResendOtp newUser={true}/>
                    </div>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Acct No.</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Activated Date</TableCell>
                                    <TableCell>Toll Tag</TableCell>
                                    <TableCell>Current Balance</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.loading ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <AccountUserTable rows={this.state.rows}/>
                                )
                            }
                        </Table>
                    </div>
                    <div>
                        <TablePagination
                            rowsPerPageOptions={[50, 75, 100]}
                            component="div"
                            count={this.state.totalData}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

class AccountUserTable extends Component {

    render() {
        return (
            <TableBody>
                {this.props.rows.map(function (item, i) {
                    let transponderStatus = item.transponderStatus;
                    let tollTagStatus = (transponderStatus === 'sent') ? 'shipped' : transponderStatus
                    return (
                        <TableRow key={i} onClick={() => history.push('/accounts/detail/' + item.id)}>
                            <TableCell>{DateFormat.dateTime(item.createdAt)}</TableCell>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.firstName}</TableCell>
                            <TableCell>{item.lastName}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            {(item.transponderNumber === null) ?
                                (<TableCell></TableCell>) :
                                (<TableCell>{DateFormat.dateTime(item.activatedDate)}</TableCell>)
                            }
                            {(item.transponderNumber === null) ?
                                (<TableCell>{tollTagStatus}</TableCell>) : (
                                    <TableCell>{item.transponderNumber}</TableCell>)
                            }
                            <TableCell>${item.balance}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }
}

export default Accounts