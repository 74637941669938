import React, {Component} from 'react';
import Chart from "chart.js";
import Api from "../../../libraries/api";
import {CircularProgress} from "@material-ui/core";

class ChartTickets extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            period: 'weekly',
            loading: false
        };
        this.getTicketChart('weekly')
        this.handleChange = this.handleChange.bind(this);
        this.getTicketChart = this.getTicketChart.bind(this);
    }

    getTicketChart(period) {
        Api.get('/reports/tickets?period=' + period, true).then(resp => {
            if (resp.data) {
                let labels = resp.data["supportTicket"]["columns"];
                let openTicket = resp.data["supportTicket"]["open"];
                let processTicket = resp.data["supportTicket"]["progress"];
                let closeTicket = resp.data["supportTicket"]["close"];
                this.setState({loading: false})
                this.__chart(labels, openTicket, processTicket, closeTicket)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    __chart = (labels, openTicket, processTicket, closeTicket) => {
        var barChartData = {
            labels: labels,
            datasets: [{
                label: 'Open',
                backgroundColor: '#008299',
                borderColor: '#008299',
                borderWidth: 1,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                },
                data: openTicket
            }, {
                label: 'Progress',
                backgroundColor: '#10d7ee',
                borderColor: '#10d7ee',
                borderWidth: 1,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                },
                data: processTicket
            }, {
                label: 'Close',
                backgroundColor: '#1846b0',
                borderColor: '#1846b0',
                borderWidth: 1,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                },
                data: closeTicket
            }]
        };
        let myChartRef = this.chartRef.current.getContext("2d");
        new Chart(myChartRef, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        }
                    }]
                },
                legend: {
                    position: 'top',
                },
            }
        }).update();
    }

    handleChange = (target, prop) => {
        this.setState({period: target, loading: true}, () => {
            this.getTicketChart(target)
        });
    };

    render() {
        return (
            <div>
                <div className="row" style={{margin:'26px 10px 0px -15px'}}>
                    <div className="card col-md-12 col-lg-12"  style={{padding:'20px 20px 0px 20px'}}>
                        <div className="row title-dashboard">
                            <div className="col-6">
                                <h2>Support tickets</h2>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-center mt-4 mb-4">
                            <nav className="nav nav-filter">
                                <button className={"nav-link pl-0 " + (this.state.period === 'daily' && 'active')}
                                        onClick={() => this.handleChange('daily', 'period')}>Daily
                                </button>
                                <button className={"nav-link " + (this.state.period === 'weekly' && 'active')}
                                        onClick={() => this.handleChange('weekly', 'period')}>Weekly
                                </button>
                                <button className={"nav-link " + (this.state.period === 'monthly' && 'active')}
                                        onClick={() => this.handleChange('monthly', 'period')}>Monthly
                                </button>
                            </nav>
                        </div>
                        <ShowTicketChart loading={this.state.loading} chartRef={this.chartRef}/>
                    </div>
                </div>
            </div>
        )
    }
}

class ShowTicketChart extends Component {
    render() {
        if (this.props.loading === true) {
            return <CircularProgress/>
        } else {
            return (
                <div className="chart-container">
                    <canvas id="myChart" ref={this.props.chartRef}/>
                </div>
            )
        }
    }
}


export default ChartTickets;